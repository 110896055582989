import './Landing.css';
import Login from'./components/Login';
function Landing () {

    return (
        <div className='container-fluid'>
            <Login />
        </div>
    )
}

export default Landing;